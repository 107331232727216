
import {
  computed,
  defineComponent, reactive, ref,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ContainerHeader from '@/components/ContainerHeader.vue';
import Container from '@/components/Container.vue';
import api from '@/api';
import { TaxPayer, Type } from '@/types/ObjectOfTrade';
import { NalogRegime } from '@/types/NalogRegime';
import { Market } from '@/types/Markets';

export default defineComponent({
  name: 'MarketsAndMallsMarketsMarketIdTaxpayerId',
  components: {
    ContainerHeader,
    Container,
  },
  props: {
    market: { type: Object },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const marketInfo = reactive({
      data: null as Market | null,
      fetching: false,
      async fetch() {
        marketInfo.fetching = true;
        try {
          // eslint-disable-next-line no-use-before-define
          const id = Number(route.params.marketId);
          if (!id) {
            marketInfo.data = null;
          } else {
            marketInfo.data = (await api.markets.getById(id)).data;
          }
        } catch (e: unknown) {
          console.log(e);
        } finally {
          marketInfo.fetching = false;
        }
      },
    });

    const info = reactive({
      values: null as TaxPayer | null,
      photo: '' as string,
      qrCode: '' as string,
      async fetch() {
        info.values = (await api.taxPayers.getById(route.params.payerId)).data;
        if (info.values.saleType === 'CONTAINER') {
          info.values.saleType = 'Контейнер';
        } else if (info.values.saleType === 'LOTOK') {
          info.values.saleType = 'Лоток';
        } else if (info.values.saleType === 'PAVILION') {
          info.values.saleType = 'Павильон';
        } else if (info.values.saleType === 'KIOSK') {
          info.values.saleType = 'Киоск';
        } else if (info.values.saleType === 'TIMETRADE') {
          info.values.saleType = 'Временная торговля';
        } else if (info.values.saleType === 'SKLAD') {
          info.values.saleType = 'Склад/Ангар';
        } else if (info.values.saleType === 'STATIONARYBUILDING') {
          info.values.saleType = 'Стационарное здание';
        } else if (info.values.saleType === 'OTHER') {
          info.values.saleType = 'Прочее';
        }

        if (info.values?.objectStatus === 'PROCESSED') {
          info.qrCode = (await api.taxPayers.getQRCodeById(`${info.values?.id || ''}`));
        }
        const photoId = `${info.values?.photo?.id || ''}`;
        if (photoId) {
          info.photo = await api.taxPayers.getPhotoId(photoId);
        }
      },
      async downloadQrCode() {
        const dataURLtoFile = (dataurl: string) => {
          const arr = dataurl.split(',');
          const bstr = atob(arr[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);
          // eslint-disable-next-line no-plusplus
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], { type: 'image/png' });
        };

        const data = window.URL.createObjectURL(dataURLtoFile(info.qrCode));

        const link = document.createElement('a');
        link.href = data;
        link.download = 'qrCode.png';
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
        }, 100);
      },
    });

    const form = reactive({
      open: false,
      values: {
        nalogRegime: null as NalogRegime | null,
        kkmUse: null as boolean | null,
        kkmCount: null as number | null,
        kkmModel: null as Type | null,
        photo: null as File | null | false, // потому что null = не изменяли, а false = было но удалили
        photoPreview: null as null | string,
      },
      options: {
        kkmUse: [
          { name: 'Да', value: true },
          { name: 'Нет', value: false },
        ],
        nalogRegime: [] as NalogRegime[],
        kkmModel: [] as Type[],
      },

      show() {
        form.reset();
        this.open = true;
      },
      hide() {
        this.open = false;
      },

      reset() {
        const vals = info.values;
        const opts = form.options;

        // взять значения из options:
        const nalogRegimeId = vals?.nalogRegime?.id || null;
        const kkmModelId = vals?.kkmModel?.id || null;
        const nalogRegime: NalogRegime | null = (nalogRegimeId && opts.nalogRegime.find((item) => item.id === nalogRegimeId)) || null;
        const kkmModel: Type | null = (kkmModelId && opts.kkmModel.find((item) => item.id === kkmModelId)) || null;

        Object.assign(this.values, {
          nalogRegime,
          kkmUse: vals?.kkmBso || null,
          kkmCount: vals?.kkmCount || null,
          kkmModel,
          photo: null,
        });
      },
      validate() {
        const {
          nalogRegime, kkmUse, kkmCount, kkmModel,
        } = form.values;
        return ![nalogRegime, kkmUse].includes(null)
          // && ![nalogRegime, kkmUse].includes(undefined)
          // если ккм используется, то ккмКаунт и ккмМодел должны быть заполнены
          && (!kkmUse || (
            kkmUse
            && [kkmCount, kkmModel].includes(null)));
      },
      prepareSubmitData() {
        const {
          kkmUse, kkmModel, nalogRegime, photo,
        } = form.values;
        let {
          kkmCount,
        } = form.values;
        // kkmUse = kkmUse;
        kkmCount = Number(kkmCount) || null;

        return {
          nalogRegime,
          kkmUse,
          kkmCount,
          kkmModel,
          photo,
        };
      },

      async fetchOptions() {
        const [kkmModel, nalogRegime] = await Promise.all([
          api.kkmModel.findAll().then(
            ({ data }) => data.map((item) => ({ ...item, name: item.value })),
          ),
          api.nalogRegime.findAll().then(({ data }) => data),
        ]);
        this.options.kkmModel = kkmModel;
        this.options.nalogRegime = nalogRegime.map((item) => ({
          ...item,
          name: [item.name?.trim(), item.type?.trim(), item.subType?.trim()].filter((a) => a).join('. '),
        }));
      },

      async submit() {
        if (!form.validate()) {
          // eslint-disable-next-line no-alert
          alert('Заполните все поля');
        }
        const { ...data } = form.prepareSubmitData();

        const body = {
          ...info.values,
          ...data,
        };
        if (info.values) {
          await api.objectsOfTrade.save(body);
        }
      },
    });

    const statusColor = computed<string>(() => {
      switch (info.values?.objectStatus) {
        case 'LIQUIDATED':
          return '#000';
        case 'NOTPROCESSED':
          return '#ff9d7e';
        case 'DUPLICATE':
          return '#FF0000';
        default:
          return '#7eb9ff';
      }
    });

    form.fetchOptions();
    info.fetch();
    form.reset();
    marketInfo.fetch();

    const inputFile = ref<HTMLInputElement>();

    return {
      info,
      form,
      inputFile,
      statusColor,
      router,
      marketInfo,
    };
  },
});
